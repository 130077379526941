import React, { useState } from 'react';
import styled from 'styled-components';

import createMarkup from '../../helpers/createMarkup';
import responsive from '../../helpers/responsive';

const CardWrapper = styled.div`
  align-items: flex-start;
  border: 1px solid #fff;
  padding: 12px;
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-gap: 16px;
  transition: all 80ms ease-in-out;
  grid-column: 4 / 14;
  line-height: 1.4;

  ${responsive('600px', {
    gridTemplateColumns: '1fr',
    gridColumn: '2 / 16',
    marginBottom: '36px',
    width: '100%',
    padding: '16px',
  })}

  ${responsive('960px', {
    gridColumn: '2 / 16',
  })}
`;

const CardTitle = styled.h4`
  font-size: 20px;
  margin-bottom: 16px;
`;

const CardExcerpt = styled.div`
  margin-bottom: 24px;
  line-height: 1.4;
  box-shadow: 0 1px 0 0 #393b39;
  padding-bottom: 12px;
`;

const CardImage = styled.img`
  background-size: contain;
  width: 200px;
`;

const CardContentWrapper = styled.div`
  display: grid;
`;

const CardContent = styled.div`
  line-height: 1.6;
  text-align: justify;
  padding-right: 16px;
  hyphens: auto;

  ul {
    list-style: disc;
    padding: 12px;

    li {
      margin-bottom: 8px;
    }
  }

  .wp-block-button {
    display: flex;

    ${responsive('600px', {
      justifyContent: 'center',
    })}
  }

  a.wp-block-button__link {
    border: 0;
    box-sizing: initial;
    background-color: #61635f;
    display: inline-block;
    color: white;
    padding: 10px 18px;
    font-size: 16px;
    font-style: normal;
    border-radius: 2px;
    width: 20%;
    justify-self: flex-end;
    text-decoration: none;
    margin-top: 36px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: #3b3d3b;
    }

    ${responsive('600px', {
      width: '60%',
      padding: '18px 24px',
    })}
  }

  a {
    text-decoration: none;
    font-style: italic;
    color: #61635f;
    cursor: pointer;
  }
`;

function Card(props) {
  const [visible, setVisible] = useState(null);

  return (
    <CardWrapper>
      <CardImage src={props.image} />
      <CardContentWrapper>
        <div>
          <CardTitle dangerouslySetInnerHTML={createMarkup(props.title)} />
          <CardExcerpt dangerouslySetInnerHTML={createMarkup(props.excerpt)} />
          <CardContent dangerouslySetInnerHTML={createMarkup(props.content)} />
        </div>
      </CardContentWrapper>
    </CardWrapper>
  );
}

export default Card;
